<script>
import { defineComponent } from 'vue'
import bottomSheet from '/~/core/bottom-sheet'
import { formatDate } from '/~/utils/format/date'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import DrawerAvatar from '/~/components/drawer/components/drawer-avatar/drawer-avatar.v4.vue'
import DefaultDrawerProfileSettings from '/~/components/drawer/views/profile/drawer-profile.vue'
import { useProvider } from '/~/composables/provider'
import DrawerActions from '/~/templates/australiapost/components/drawer/components/actions/drawer-actions.vue'

export default defineComponent({
  components: {
    BaseAsidePage,
    BaseIcon,
    DrawerActions,
    DrawerAvatar,
  },
  extends: DefaultDrawerProfileSettings,
  setup(props) {
    const { isDisplayMemberSince, isAvatarVisible } = useProvider()

    function onChangeAvatar() {
      bottomSheet.show('avatar', {
        to: props.to,
      })
    }

    return {
      ...DefaultDrawerProfileSettings.setup?.(...arguments),
      formatDate,
      isDisplayMemberSince,
      isAvatarVisible,
      onChangeAvatar,
    }
  },
})
</script>

<template>
  <base-aside-page title="Profile">
    <div class="mb-[30px]">
      <drawer-avatar
        v-if="isAvatarVisible"
        :avatar-url="user.avatarUrl"
        :action="onChangeAvatar"
        class="mx-auto h-56 w-56"
        large
      />
      <div class="mt-[30px] text-center text-xl font-bold leading-7">
        {{ user.fullName }}
      </div>
      <div
        v-if="isDisplayMemberSince"
        class="relative flex flex-row items-center justify-center"
      >
        <div
          class="mx-auto mt-2.5 flex flex-row items-center rounded-full border px-5 py-2.5 text-sm text-eonx-neutral-600"
        >
          <base-icon svg="australiapost/calendar2" :size="18" class="mr-2.5" />
          <span class="mr-[5px]">Member since</span>
          <span class="font-semibold">
            {{ formatDate('daymonthyear', user.joinDate) }}
          </span>
        </div>
      </div>
    </div>

    <drawer-actions v-if="items.length" :actions="items" />
  </base-aside-page>
</template>
