<script>
import bottomSheet from '/~/core/bottom-sheet'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import DrawerActions from '/~/components/drawer/components/actions/drawer-actions.vue'
import DrawerAvatar from '/~/components/drawer/components/drawer-avatar/drawer-avatar.vue'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'

export default {
  name: 'drawer-profile',
  components: {
    DrawerAvatar,
    DrawerActions,
    BaseAsidePage,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    to: {
      type: String,
      default: 'menu-modal',
    },
  },
  setup(props) {
    const { user } = useUser()
    const { isAvatarVisible } = useProvider()

    function onChangeAvatar() {
      bottomSheet.show('avatar', {
        to: props.to,
      })
    }

    return {
      onChangeAvatar,
      user,
      isAvatarVisible,
    }
  },
}
</script>

<template>
  <base-aside-page title="Profile">
    <div>
      <drawer-avatar
        v-if="isAvatarVisible"
        style="margin-top: 0"
        :avatar-url="user.avatarUrl"
        :action="onChangeAvatar"
      />
      <div class="mb-[35px] mt-6 text-center text-2xl font-bold leading-7">
        {{ user.fullName }}
      </div>
    </div>

    <drawer-actions v-if="items.length" :actions="items" />
  </base-aside-page>
</template>
